import React, { useEffect } from 'react'
import Coin from '../../assets/icons/coin.svg'
import Dex from '../../assets/icons/dex.svg'
import Geko from '../../assets/icons/geko.svg'

const SocialLinks = () => {
    useEffect(() => {
        document.getElementById("google_translate_element").hidden = false;
    }, [])
    return (
        <div className="social-container">
            <div className="social-links">
                <div className="container">
                    <a target="_blank" href="https://www.facebook.com/safepizza" className="social-icon">
                        <span className="social-cell">
                            <i class="fab fa-facebook-f"></i>
                        </span>
                    </a>
                    <a target="_blank" href="https://www.instagram.com/safepizza_official/" className="social-icon">
                        <span className="social-cell">
                            <i class="fab fa-instagram"></i>
                        </span>
                    </a>
                    <a target="_blank" href="https://twitter.com/safe_pizza" className="social-icon">
                        <span className="social-cell">
                            <i class="fab fa-twitter"></i>
                        </span>
                    </a>
                    <a target="_blank" href="https://t.me/safepizza" className="social-icon">
                        <span className="social-cell">
                            <i class="fab fa-telegram-plane"></i>
                        </span>
                    </a>
                    <a target="_blank" href="https://discord.com/invite/etaeKzr4nk" className="social-icon">
                        <span className="social-cell">
                            <i class="fab fa-discord"></i>
                        </span>
                    </a>
                    <a target="_blank" href="https://www.tiktok.com/@safe_pizza" className="social-icon">
                        <span className="social-cell">
                            <i class="fab fa-tiktok"></i>
                        </span>
                    </a>

                    <a target="_blank" href="https://www.youtube.com/channel/UCXF97e7bKt18NoiB0TLWAVQ" className="social-icon">
                        <span className="social-cell">
                            <i class="fab fa-youtube"></i>
                        </span>
                    </a>
                    {/* <a target="_blank" href="#" className="social-icon">
                    <span className="social-cell">
                        <i class="fab fa-medium-m"></i>
                    </span>
                </a> */}
                    <a target="_blank" href="https://www.reddit.com/r/SafePizzaofficial/?utm_medium=android_app&utm_source=share" className="social-icon">
                        <span className="social-cell">
                            <i class="fab fa-reddit-alien"></i>
                        </span>
                    </a>
                    <a target="_blank" href="https://www.linkedin.com/company/safepizzaofficial" className="social-icon">
                        <span className="social-cell">
                            <i class="fab fa-linkedin-in"></i>
                        </span>
                    </a>
                    {/* <a target="_blank" href="https://poocoin.app/tokens/0x72eb1afddb5652e0f5c7b9a6cc1c3241348b16c6" className="social-icon">
                        <span className="social-cell">
                            <i class="fas fa-poo"></i>
                        </span>
                    </a>
                    <a target="_blank" href="https://bscscan.com/token/0x72eb1afddb5652e0f5c7b9a6cc1c3241348b16c6" className="social-icon">
                        <span className="social-cell">
                            <i class="fas fa-chart-line"></i>
                        </span>
                    </a>
                    <a target="_blank" href="https://dex.guru/token/0x72eb1afddb5652e0f5c7b9a6cc1c3241348b16c6-bsc" className="social-icon">
                        <span className="social-cell">
                            <img src={Dex} alt="" />
                        </span>
                    </a>
                    <a target="_blank" href="https://coinmarketcap.com/currencies/safepizza/" className="social-icon">
                        <span className="social-cell">
                            <img src={Coin} alt="" />
                        </span>
                    </a>
                    <a target="_blank" href="https://www.coingecko.com/en/coins/safepizza" className="social-icon">
                        <span className="social-cell">
                            <img src={Geko} alt="" />
                        </span>
                    </a> */}
                </div>
            </div>

        </div>
    )
}

export default SocialLinks
