import React from 'react'
import MainImage from '../../assets/ast/ast-02.png'
import SignUp from '../../assets/signup/signup.png'

const DocBox = () => {
    return (
        <div>
            <div className="new-container">
                <div className="main-doc-box-container">
                    <div className="row">
                        <div className="col-md-5">
                            <div className="migration-div mobile">
                                <h3>SAFEPIZZA MIGRATION</h3>
                            </div>
                            <a className="received-02" href="https://bscscan.com/address/0x5Da0C43326180C1cc4D9561c0E4281b5B329C3Cd#tokentxns" target="_blank">
                                <div className="doc-box">
                                    <h3>RECEIVED</h3>
                                </div>
                            </a>
                            <a className="not-received" href="https://bscscan.com/token/0x72eb1afddb5652e0f5c7b9a6cc1c3241348b16c6#balances" target="_blank">
                                <div className="doc-box">
                                    <h3>NOT RECEIVED</h3>
                                </div>
                            </a>
                            <div className="signup-image">
                                <a href="https://www.snapshot.safe-pizza.com" target="_blank">
                                    <img src={SignUp} alt="" className="img-responsive" />
                                </a>
                            </div>
                            <div className="help-buttons">
                                <a href="https://pizzav2.safe-pizza.com/" target="_blank">PIZZA V2</a>
                                <a href="https://t.me/safepizzamigration" target="_blank">HELP</a>
                            </div>
                        </div>
                        <div className="col-md-7">
                            <div className="migration-div desktop">
                                <h3>SAFEPIZZA MIGRATION</h3>
                            </div>
                            <div className="antronut">
                                <img src={MainImage} alt="" />
                            </div>
                            <div className="instruction-div">
                                <h4>INSTRUCTIONS</h4>
                                <ul>
                                    <li>PLEASE FILL OUT EMAIL SIGN UP</li>
                                    <li>PLEASE SEND US ALL YOUR TOKEN TO THE ADDRESS SENT TO YOU IN EMAIL</li>
                                    <li>PLEASE DO NOT SELL, YOU WILL NOT GET SAFEPIZZA V2 IF YOU SELL BEFORE SNAPSHOT OR AFTER SNAPSHOT</li>
                                    <li>PLEASE DO NOT BUY AFTER SNAPSHOT, IT WILL NOT COUNT. YOU WILL NOT GET SAFEPIZZA V2 NEW TOKENS </li>
                                    <li>CLICK HELP LINK (will send them to T.me/safepizzaMIGRATION</li>
                                    <li>CHECK INTO SAFEPIZZA TELEGRAM: T.me/safepizza</li>
                                    <li>SIGN UP FOR DETAILS TO MIGRATE ON MEDIUM: https://medium.com/@safe_pizza</li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default DocBox
