import React from 'react'
import Logo from '../../assets/logo.png'
import Coin from '../../assets/icons/coin.svg'
import Dex from '../../assets/icons/dex.svg'
import Geko from '../../assets/icons/geko.svg'

const NavBar = () => {
    return (
        <div className="main-nav-bar">
            <div className="container">
                <div className="nav-container">
                    <div className="logo-container">
                        <a href="https://officialrecord.safe-pizza.com/" target="_blank">SNAPSHOT</a>
                    </div>
                    <div className="nav-bar-items">
                        <div className="social-links">
                            <div className="test">
                                <a target="_blank" href="https://www.facebook.com/safepizza" className="social-icon">
                                    <span className="social-cell">
                                        <i class="fab fa-facebook-f"></i>
                                    </span>
                                </a>
                                <a target="_blank" href="https://www.instagram.com/safepizza_official/" className="social-icon">
                                    <span className="social-cell">
                                        <i class="fab fa-instagram"></i>
                                    </span>
                                </a>
                                <a target="_blank" href="https://twitter.com/safe_pizza" className="social-icon">
                                    <span className="social-cell">
                                        <i class="fab fa-twitter"></i>
                                    </span>
                                </a>
                                <a target="_blank" href="https://t.me/safepizza" className="social-icon">
                                    <span className="social-cell">
                                        <i class="fab fa-telegram-plane"></i>
                                    </span>
                                </a>
                                <a target="_blank" href="https://discord.com/invite/etaeKzr4nk" className="social-icon">
                                    <span className="social-cell">
                                        <i class="fab fa-discord"></i>
                                    </span>
                                </a>
                                <a target="_blank" href="https://www.tiktok.com/@safe_pizza" className="social-icon">
                                    <span className="social-cell">
                                        <i class="fab fa-tiktok"></i>
                                    </span>
                                </a>

                                <a target="_blank" href="https://www.youtube.com/channel/UCXF97e7bKt18NoiB0TLWAVQ" className="social-icon">
                                    <span className="social-cell">
                                        <i class="fab fa-youtube"></i>
                                    </span>
                                </a>
                                {/* <a target="_blank" href="#" className="social-icon">
                    <span className="social-cell">
                        <i class="fab fa-medium-m"></i>
                    </span>
                </a> */}
                                <a target="_blank" href="https://www.reddit.com/r/SafePizzaofficial/?utm_medium=android_app&utm_source=share" className="social-icon">
                                    <span className="social-cell">
                                        <i class="fab fa-reddit-alien"></i>
                                    </span>
                                </a>
                                <a target="_blank" href="https://www.linkedin.com/company/safepizzaofficial" className="social-icon">
                                    <span className="social-cell">
                                        <i class="fab fa-linkedin-in"></i>
                                    </span>
                                </a>
                                {/* <a target="_blank" href="https://poocoin.app/tokens/0x72eb1afddb5652e0f5c7b9a6cc1c3241348b16c6" className="social-icon">
                                    <span className="social-cell">
                                        <i class="fas fa-poo"></i>
                                    </span>
                                </a>
                                <a target="_blank" href="https://bscscan.com/token/0x72eb1afddb5652e0f5c7b9a6cc1c3241348b16c6" className="social-icon">
                                    <span className="social-cell">
                                        <i class="fas fa-chart-line"></i>
                                    </span>
                                </a>
                                <a target="_blank" href="https://dex.guru/token/0x72eb1afddb5652e0f5c7b9a6cc1c3241348b16c6-bsc" className="social-icon">
                                    <span className="social-cell">
                                        <img src={Dex} alt="" />
                                    </span>
                                </a>
                                <a target="_blank" href="https://coinmarketcap.com/currencies/safepizza/" className="social-icon">
                                    <span className="social-cell">
                                        <img src={Coin} alt="" />
                                    </span>
                                </a>
                                <a target="_blank" href="https://www.coingecko.com/en/coins/safepizza" className="social-icon">
                                    <span className="social-cell">
                                        <img src={Geko} alt="" />
                                    </span>
                                </a> */}
                            </div>
                        </div>

                    </div>


                    <div className="mobile-nav">
                        <nav class="navbar navbar-inverse">
                            <div class="navbar-header">
                                <button type="button" class="navbar-toggle" data-toggle="collapse" data-target="#myNavbar">
                                    <span class="icon-bar"></span>
                                    <span class="icon-bar"></span>
                                    <span class="icon-bar"></span>
                                </button>
                                <div className="mobile-logo-container">
                                    <a href="https://officialrecord.safe-pizza.com/" target="_blank">SNAPSHOT</a>
                                </div>
                            </div>
                            <div class="collapse navbar-collapse" id="myNavbar">
                                <ul class="nav navbar-nav">
                                    <li><a href="/howtobuy">How To Buy</a></li>
                                    <li><a href="/home/#roadmap">Roadmap</a></li>
                                    <li><a href="/documents-audits">Documents & Audits</a></li>
                                    <li><a target="_blank" href="http://shop.safe-pizza.com">Shop</a></li>
                                    <li><a href="/faq">FAQ</a></li>
                                    <li><a href="/home/#contactus">Contact</a></li>
                                    <li><a href="https://poocoin.app/tokens/0x72eb1afddb5652e0f5c7b9a6cc1c3241348b16c6">Charts</a></li>
                                    <li><a href="/team">Team</a></li>
                                    <li><a href="/media">Media</a></li>
                                </ul>
                            </div>
                        </nav>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default NavBar
